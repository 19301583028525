import BlogBody from '../components/Blogbody'
import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { v4 as uuidv4 } from 'uuid'
import get from 'lodash/get'
import Layout from '../components/Layout'

import { GatsbyImage } from 'gatsby-plugin-image'

import Progress from '../components/Progress'

interface StateProps {
  activeHeadingId: string
  headingsTwo: Array<object>
  clickedToc: boolean
  showTOC: boolean
}

class BlogPostTemplate extends React.Component<{}, StateProps> {
  instance: HTMLElement
  constructor(props) {
    super(props)
    this.state = {
      activeHeadingId: null,
      headingsTwo: [],
      clickedToc: false,
      showTOC: false,
    }
  }
  componentDidMount() {
    const script = document.createElement('script')
    script.src = 'https://static.addtoany.com/menu/page.js'
    script.async = true
    this.instance.appendChild(script)

    let h2s = document.querySelectorAll<HTMLElement>('.heading-toc')
    let arrayOfH2sText = []
    // Getting text from h2 elements
    for (const h2 of h2s) {
      let h2Text = h2.innerText
      arrayOfH2sText.push(h2Text)
    }

    function isInViewport(element) {
      const rect = element.getBoundingClientRect()
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      )
    }

    const handleActiveHeader = () => {
      // Checking here to disable auto selecting the next one
      // If more than one in viewport when user selects one
      if (!this.state.clickedToc) {
        let h2s = document.querySelectorAll('.heading-toc')
        let activeh2
        // Loop through headings
        for (const h2 of h2s) {
          // Check if element is further than we've scrolled
          if (h2.getBoundingClientRect().top > 0) {
            // Check if element is in viewport
            if (isInViewport(h2)) {
              activeh2 = h2.id
            }
          }
        }
        if (activeh2) {
          this.setState({
            ...this.state,
            activeHeadingId: activeh2,
          })
        }
      } else {
        this.setState({
          ...this.state,
          clickedToc: false,
        })
      }
    }

    // Triggering active header
    window.addEventListener('scroll', handleActiveHeader)

    // Setting state with the h2 element text
    // Setting showTOC based on length of blog main body content
    this.setState({
      ...this.state,
      headingsTwo: arrayOfH2sText,
      showTOC:
        document
          .querySelector<HTMLElement>('.blog-body-container')
          .innerText.split(' ').length > 1500,
    })

    const handleVisibility = () => {
      if (window.screen.width > 1200) {
        if (
          document.querySelector('.related-blogs').getBoundingClientRect().top -
            500 <
          0
        ) {
          document
            .querySelector('.toc-table-container')
            ?.classList.remove('d-xl-block')
        } else {
          document
            .querySelector('.toc-table-container')
            ?.classList.add('d-xl-block')
        }
      }
    }
    window.onscroll = () => {
      handleVisibility()
    }
  }
  // Preventing Re-render
  shouldComponentUpdate = (nextProps, nextState) => {
    // Check for headings
    // If showTOC is true need to rerender
    // to update active heading
    if (
      this.state.showTOC === false &&
      this.state.showTOC === nextState.showTOC
    ) {
      return false
    } else {
      return true
    }
  }

  render() {
    const post = get(this.props, 'data.contentfulLensesBlog')
    const tags = get(this, 'props.data.contentfulLensesBlog.tags')
    const blogs = get(this, 'props.data.allContentfulLensesBlog.edges')

    // GET RELEVANT TAG BLOG
    let thisBlogFirstTag = tags[0].tagTitle
    let relevantBlogTag
    let tempDateTag
    let dateToDisplayTag
    let yearTag
    let monthTag

    let checkTag = 0
    blogs.forEach((blog) => {
      // check for control and unequal to exclude current
      if (checkTag === 0 && post.title !== blog.node.title) {
        let blogTags = blog.node.tags
        blogTags.forEach((blogTag) => {
          if (thisBlogFirstTag === blogTag.tagTitle) {
            relevantBlogTag = blog.node
            tempDateTag = relevantBlogTag.date
            dateToDisplayTag = new Date(tempDateTag)
            yearTag = dateToDisplayTag.getFullYear()
            monthTag = dateToDisplayTag.getMonth()
            monthTag++
            monthTag = ('0' + monthTag).slice(-2)
            checkTag = 1
          }
        })
      }
    })

    // Get Relevant blogs Categories

    let thisBlogFirstCategory = post.categories[0].categoryTitle
    let checkCategory = 0
    let relevantBlogsCategory = []

    // Also filter for tag blog
    blogs.forEach((blog) => {
      if (
        // Check not to be the same post as on page
        checkCategory < 2 &&
        post.title !== blog.node.title
      ) {
        if (relevantBlogTag) {
          if (post.title !== relevantBlogTag.title) {
            let blogCategories = blog.node.categories
            blogCategories.forEach((blogCategory) => {
              if (thisBlogFirstCategory === blogCategory.categoryTitle) {
                // Check not to be the same post as on categories
                if (blog.node.title !== relevantBlogTag.title) {
                  relevantBlogsCategory.push(blog.node)
                  checkCategory++
                }
              }
            })
          }
        } else {
          let blogCategories = blog.node.categories
          blogCategories.forEach((blogCategory) => {
            if (thisBlogFirstCategory === blogCategory.categoryTitle) {
              // Check not to be the same post as on categories
              relevantBlogsCategory.push(blog.node)
              checkCategory++
            }
          })
        }
      }
    })

    const headingClicked = (e) => {
      // Used to restore initial position in mobile/lg screens
      document.querySelector('#collapseTOC').classList.remove('show')
      document.querySelector<HTMLElement>('#tocTrigger').innerText =
        'Table of Contents →'

      this.setState({
        ...this.state,
        activeHeadingId: 'heading' + e.target.id,
        clickedToc: true,
      })
    }

    // Create Date format
    const tempDate = get(this.props, 'data.contentfulLensesBlog.date')
    const date = new Date(tempDate)
    const dateOptions = {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    } as const

    const handleCollapse = (e) => {
      if (e.target.innerText === 'Table of Contents →') {
        e.target.innerText = 'Table of Contents ↑'
      } else {
        e.target.innerText = 'Table of Contents →'
      }
    }

    return (
      <Fragment>
        <Layout>
          <Progress />
          <Helmet>
            <title>{post.seoTitle} | Lenses.io Blog</title>
            <meta name="twitter:text:title" content={post.seoTitle} />
            <meta name="description" content={post.description} />
            <meta property="og:description" content={post.description} />
            <meta property="twitter:description" content={post.description} />

            <meta name="keywords" content={post.keywords.keywords} />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@lensesio" />
            <meta property="og:title" content={post.seoTitle} />
            <meta property="twitter:title" content={post.seoTitle} />
            <meta
              property="og:image"
              content={'https:' + post.heroimage.fluid.src}
            />
            <meta
              property="twitter:image"
              content={'https:' + post.heroimage.fluid.src}
            />
          </Helmet>
          <div className="container-1 hero-margin">
            <div className="d-flex flex-wrap justify-content-center mobile-padding-top-half">
              <div className="col-lg-9 col-md-12 col-12 blog-content-parent ">
                <h1 className="lh-2-3 text-center">{post.title}</h1>
                <div className="w-75 mx-auto mt-4">
                  <h2 className="text-center my-2 fw-400 f-20 lh-32">
                    {post.subtitle}
                  </h2>
                </div>
                <div className="d-flex justify-content-center my-3">
                  <GatsbyImage
                    image={post.author.image.gatsbyImageData}
                    loading="eager"
                    className="mr-2 author-photo-blog"
                    alt={post.author.name}
                  />
                  <div className="pl-1 f-14">
                    {post.author.display ? (
                      <Fragment>
                        <div className="fw-600">
                          By{' '}
                          <a
                            href={`/author/${post.author.name
                              .replace(/\s+/g, '-')
                              .toLowerCase()}/`}
                          >
                            {' '}
                            <span className="primary-text">
                              {' '}
                              {post.author.name}
                            </span>
                          </a>{' '}
                        </div>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <div className="fw-600">By {post.author.name}</div>
                      </Fragment>
                    )}
                    {date.toLocaleDateString('en-US', dateOptions)}
                  </div>
                </div>
                <div>
                  <GatsbyImage
                    className="img-fluid"
                    alt={post.title}
                    image={post.heroimage.gatsbyImageData}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container-1">
            <div className="mt-3">
              <div className="d-flex flex-wrap justify-content-center">
                {/* For XL screens */}
                {this.state.showTOC && (
                  <div className="p-relative">
                    <div className="toc-table-container toc-table-container-scroll d-xl-block d-none">
                      <div className="toc-table rounded-1 shadow-sm">
                        <div className="footer-color rounded p-4">
                          <ul className="list-unstyled">
                            {this.state.headingsTwo &&
                              this.state.headingsTwo.map((heading2, index) => {
                                return (
                                  <li key={uuidv4()}>
                                    <a
                                      id={`${index}`}
                                      className={`toc-header f-16 roboto fw-700 ${
                                        this.state.activeHeadingId ==
                                        'heading' + index
                                          ? 'active-heading'
                                          : ''
                                      } `}
                                      href={`#heading${index}`}
                                      onClick={(e) => headingClicked(e)}
                                    >
                                      {heading2}
                                    </a>
                                  </li>
                                )
                              })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* For mobile to large screens */}
                {this.state.showTOC && (
                  <div className="d-flex flex-wrap justify-content-center">
                    <div className="toc-table-container-lg toc-table-container-scroll d-xl-none d-block z-index-999">
                      <div className="collapse" id="collapseTOC">
                        <div className="toc-table-lg rounded-1 shadow-sm">
                          <div className="footer-color rounded p-4">
                            <ul className="list-unstyled">
                              {this.state.headingsTwo &&
                                this.state.headingsTwo.map(
                                  (heading2, index) => {
                                    return (
                                      <li key={uuidv4()}>
                                        <a
                                          id={`${index}`}
                                          className={`toc-header f-16 roboto fw-700 ${
                                            this.state.activeHeadingId ==
                                            'heading' + index
                                              ? 'active-heading'
                                              : ''
                                          } `}
                                          href={`#heading${index}`}
                                          onClick={(e) => headingClicked(e)}
                                        >
                                          {heading2}
                                        </a>
                                      </li>
                                    )
                                  }
                                )}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="w-100 footer-color p-3 border-0">
                        <a
                          className="text-left d-block"
                          data-toggle="collapse"
                          href="#collapseTOC"
                          onClick={(e) => handleCollapse(e)}
                          id="tocTrigger"
                          role="button"
                          aria-expanded="false"
                          aria-controls="collapseTOC"
                        >
                          Table of Contents &rarr;
                        </a>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className={`blog-body-container ${
                    this.state.showTOC
                      ? 'offset-0 offset-lg-3 col-lg-9 col-md-12 col-12 border-top mt-3 pt-3'
                      : 'col-lg-9 col-md-12 col-12'
                  } `}
                >
                  {/* BLOG BODY */}
                  <div key={uuidv4()}>
                    <BlogBody blogState={this.state} body={post.blogBody} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="social-container">
            <div
              ref={(el) => (this.instance = el)}
              className="a2a_kit a2a_kit_size_32 a2a_default_style d-flex flex-column"
            >
              <a className="a2a_button_whatsapp  px-1 my-1"></a>
              <a className="a2a_button_reddit  px-1 my-1"></a>
              <a
                className="a2a_button_twitter a2a_counter px-1 my-1"
                id="blogpost-ending-social-button-twitter"
              ></a>
              <a
                className="a2a_button_linkedin a2a_counter px-1 my-1"
                id="blogpost-ending-social-button-linkedin"
              ></a>
              <a className="a2a_button_hacker_news px-1 my-1"></a>
            </div>
          </div>

          {/* RELATED BLOGS */}
          <section className="related-blogs">
            <div className="mt-5 mb-1 ">
              <div className="container-1">
                <div className="d-flex flex-wrap">
                  <div className="mobile-padding-reset">
                    <div className="paragraph-title pb-0 exclude-algolia-title">
                      <div className="col-12">Related Blogs</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-0 mb-5">
                <div className="container-1">
                  <div className="d-flex flex-wrap mobile-margin-reset">
                    {/* Tag Related blog(1) */}
                    {relevantBlogTag && (
                      <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-10 offset-sm-0 offset-1 scale-on-hover p-3 mobile-padding-reset mobile-margin-top">
                        <div
                          style={{
                            boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.08)',
                          }}
                        >
                          <a
                            href={`/blog/${yearTag}/${monthTag}/${relevantBlogTag.slug}/`}
                          >
                            {/* heroImage here */}
                            <GatsbyImage
                              className="img-fluid "
                              image={relevantBlogTag.heroimage.gatsbyImageData}
                              alt={relevantBlogTag.title}
                            />
                          </a>

                          <div className="height-250 p-3">
                            <a
                              href={`/blog/${yearTag}/${monthTag}/${relevantBlogTag.slug}/`}
                            >
                              <div className="paragraph-title f-18 pb-3 lh-1-3 exclude-algolia-title">
                                {relevantBlogTag.title}
                              </div>
                              <p className="paragraph-text">
                                {relevantBlogTag.subtitle.substring(0, 70)}...
                              </p>
                            </a>
                            <div className="d-flex blog-image-author blog-image-author-static">
                              {/* Authors here */}
                              {relevantBlogTag.author.display ? (
                                <Fragment>
                                  <a
                                    href={`/author/${relevantBlogTag.author.name
                                      .replace(/\s+/g, '-')
                                      .toLowerCase()}/`}
                                  >
                                    <GatsbyImage
                                      className="author-photo-blog mr-3"
                                      image={
                                        relevantBlogTag.author.image
                                          .gatsbyImageData
                                      }
                                      alt={relevantBlogTag.author.name}
                                    />
                                  </a>
                                  <p className="pl-2 f-14 mr-3 fw-600 text-left">
                                    {' '}
                                    <a
                                      href={`/author/${relevantBlogTag.author.name
                                        .replace(/\s+/g, '-')
                                        .toLowerCase()}/`}
                                    >
                                      <span className="primary-text">
                                        {relevantBlogTag.author.name}
                                      </span>
                                    </a>
                                    <br />
                                    {dateToDisplayTag.toLocaleDateString(
                                      'en-US',
                                      dateOptions
                                    )}
                                  </p>
                                </Fragment>
                              ) : (
                                <Fragment>
                                  <GatsbyImage
                                    className="author-photo-blog mr-3"
                                    image={
                                      relevantBlogTag.author.image
                                        .gatsbyImageData
                                    }
                                    alt={relevantBlogTag.author.name}
                                  />
                                  <p className="pl-2 f-14 mr-3 fw-600 text-left">
                                    {' '}
                                    {relevantBlogTag.author.name}
                                    <br />
                                    {dateToDisplayTag.toLocaleDateString(
                                      'en-US',
                                      dateOptions
                                    )}
                                  </p>
                                </Fragment>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* Categories Related blogs 2 */}
                    {relevantBlogsCategory.map((relevantBlog) => {
                      let tempDateCategory = relevantBlog.date
                      let dateToDisplayCategory = new Date(tempDateCategory)
                      let yearCategoryBlog = dateToDisplayCategory.getFullYear()
                      let monthCategoryBlog = dateToDisplayCategory.getMonth()
                      monthCategoryBlog++
                      // Getting the month from the date & properly formatting - used in the url
                      let monthCategoryBlogToString = (
                        '0' + monthCategoryBlog
                      ).slice(-2)
                      // console.log(relevantBlog)
                      return (
                        <Fragment key={relevantBlog.id}>
                          <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-10 offset-sm-0 offset-1 scale-on-hover p-3 mobile-padding-reset mobile-margin-top">
                            <div
                              style={{
                                boxShadow:
                                  '0px 4px 8px 0px rgba(0, 0, 0, 0.08)',
                              }}
                            >
                              <a
                                href={`/blog/${yearCategoryBlog}/${monthCategoryBlogToString}/${relevantBlog.slug}/`}
                              >
                                <GatsbyImage
                                  className="img-fluid"
                                  image={relevantBlog.heroimage.gatsbyImageData}
                                  alt={relevantBlog.title}
                                />
                              </a>
                              <div className="height-250 p-3 mb-4">
                                <a
                                  href={`/blog/${yearCategoryBlog}/${monthCategoryBlogToString}/${relevantBlog.slug}/`}
                                >
                                  <div className="paragraph-title f-18 pb-3 lh-1-3 exclude-algolia-title">
                                    {relevantBlog.title}
                                  </div>

                                  <p className="paragraph-text ">
                                    {relevantBlog.subtitle.substring(0, 70)}
                                    ...
                                  </p>
                                </a>
                                <div className="d-flex blog-image-author blog-image-author-static">
                                  {/* Author with link here */}
                                  {relevantBlog.author.display ? (
                                    <Fragment>
                                      <a
                                        href={`/author/${relevantBlog.author.name
                                          .replace(/\s+/g, '-')
                                          .toLowerCase()}/`}
                                      >
                                        <GatsbyImage
                                          className="author-photo-blog mr-3"
                                          image={
                                            relevantBlog.author.image
                                              .gatsbyImageData
                                          }
                                          alt={relevantBlog.author.name}
                                        />
                                      </a>
                                      <p className="pl-2 f-14 mr-3 fw-600 text-left">
                                        {' '}
                                        <a
                                          href={`/author/${relevantBlog.author.name
                                            .replace(/\s+/g, '-')
                                            .toLowerCase()}/`}
                                        >
                                          <span className="primary-text">
                                            {relevantBlog.author.name}
                                          </span>
                                        </a>
                                        <br />
                                        {dateToDisplayCategory.toLocaleDateString(
                                          'en-US',
                                          dateOptions
                                        )}
                                      </p>
                                    </Fragment>
                                  ) : (
                                    <Fragment>
                                      <GatsbyImage
                                        className="author-photo-blog mr-3"
                                        image={
                                          relevantBlog.author.image
                                            .gatsbyImageData
                                        }
                                        alt={relevantBlog.author.name}
                                      />
                                      <p className="pl-2 f-14 mr-3 fw-600 text-left">
                                        {' '}
                                        <span>{relevantBlog.author.name}</span>
                                        <br />
                                        {dateToDisplayCategory.toLocaleDateString(
                                          'en-US',
                                          dateOptions
                                        )}
                                      </p>
                                    </Fragment>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Fragment>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="mobile-margin-bottom mobile-margin-top my-5">
            <div className="container-1 bg-dark rounded-1 p-5">
              <div className="d-flex flex-wrap">
                <div className="col-md-12 col-12 text-center roll-button">
                  <p className="mb-5 paragraph-title text-white">
                    Ready to get started with Lenses?
                  </p>
                  <a className="bg-white primary-text" href="/start/">
                    <span data-hover="Try now for free"> Try now for free</span>
                  </a>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      </Fragment>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulLensesBlog(slug: { eq: $slug }) {
      title
      heroimage {
        fluid(quality: 100) {
          src
        }
        gatsbyImageData(layout: FULL_WIDTH)
        file {
          url
        }
      }
      author {
        name
        display
        image {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      tags {
        id
        tagTitle
      }
      categories {
        categoryTitle
      }
      keywords {
        keywords
      }
      seoTitle
      slug
      date
      subtitle
      description
      blogBody {
        raw
        references {
          ... on ContentfulCodeSnippet {
            contentful_id
            code {
              code
            }
            style
            language
            title
            internal {
              type
            }
          }
          ... on ContentfulAsset {
            contentful_id
            title
            __typename
            file {
              url
            }
          }
          ... on ContentfulVideo {
            contentful_id
            videoId
            internal {
              type
            }
          }
        }
      }
    }
    allContentfulLensesBlog(sort: { fields: date, order: DESC }) {
      edges {
        node {
          author {
            name
            display
            image {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          description
          subtitle
          date
          id
          slug
          title
          heroimage {
            fluid(quality: 100) {
              src
            }
            gatsbyImageData(layout: FULL_WIDTH)
          }
          tags {
            tagTitle
          }
          categories {
            categoryTitle
          }
        }
      }
    }
  }
`
